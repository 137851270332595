import * as React from "react";
import BaseLayout from "../../components/layouts/baseLayout";

const CartHome: React.FunctionComponent = () => (
    <BaseLayout>
        <h1>Cart</h1>
    </BaseLayout>
);

export default CartHome;
